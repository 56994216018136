import i18next from 'i18next';
import Enum from './Enum';

class PaymentChannel extends Enum {
  PAYPAL = 1;

  WXPAY = 2;

  ALIPAY = 3;

  STRIPE = 4;

  STRIPE_3RD_PARTY = 5;

  TYRO = 7;

  TYRO_APPLE = 8;

  TYRO_GOOGLE = 9;

  WINDCAVE = 10;

  WINDCAVE_APPLE = 11;

  WINDCAVE_GOOGLE = 12;

  ADYEN = 13;

  labels() {
    const labels = {};
    labels[this.PAYPAL] = i18next.t('payment.paypal');
    labels[this.WXPAY] = i18next.t('payment.wechatpay');
    labels[this.ALIPAY] = i18next.t('payment.alipay');
    labels[this.STRIPE] = i18next.t('payment.stripe');
    labels[this.STRIPE_3RD_PARTY] = i18next.t('payment.other');
    labels[this.TYRO] = i18next.t('payment.tyro');
    labels[this.TYRO_APPLE] = i18next.t('payment.other');
    labels[this.TYRO_GOOGLE] = i18next.t('payment.other');
    labels[this.WINDCAVE] = i18next.t('payment.windcave');
    labels[this.WINDCAVE_APPLE] = i18next.t('payment.other');
    labels[this.WINDCAVE_GOOGLE] = i18next.t('payment.other');
    labels[this.ADYEN] = i18next.t('payment.adyen');
    return labels;
  }
}

export default new PaymentChannel();
